import React from "react"
import Intercom from "@intercom/messenger-js-sdk"

const IntercomComponent = () => {
  Intercom({
    app_id: "nb2w88mv",
  })

  return <></>
}

export default IntercomComponent
