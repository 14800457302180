import React from "react"
import IntercomComponent from "./src/components/Intercom"
import ToastProvider from "./src/components/ToastProvider"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {element}
      <IntercomComponent />
      <ToastProvider />
    </>
  )
}
